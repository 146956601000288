import React from 'react'
import styled, { css } from 'styled-components'
import { DIN } from '../../tokens/fontStyles'

export const OutlineButton = ({ children }) => {
  return <Outlined>{children}</Outlined>
}

const ButtonBaseStyle = css`
  display: inline-block;
  padding: 15px 30px 18px;
  text-align: center;
  transition: 0.4s;
  font-size: 1.4rem;
  font-weight: bold;
`
const Outlined = styled.button`
  ${ButtonBaseStyle};
  ${DIN};
  border: 1px solid #ffffff;
  color: #ffffff;
`
export const OutlinedStyle = css`
  ${ButtonBaseStyle};
  ${DIN};
  border: 1px solid #ffffff;
  color: #ffffff;
`
