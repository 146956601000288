import React from 'react'
import styled from 'styled-components'
import bk from '../../../images/video01.jpg'
import dot from '../../../images/video02.png'
import { DIN } from '../../tokens/fontStyles'
import { centerize, alignVertical } from '../../tokens/positionings'
import colors from '../../tokens/colors'
import { BigMessage } from '../../tokens/fontStyles'

import PlayButton from '../../atoms/buttons/PlayButton'

import YouTube from 'react-modal-video'
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';

class Component extends React.Component {
  constructor({id}) {
    super()
    this.id = id
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal = () => {
    this.setState({isOpen: true})
  }
  render (){
    return (
      <React.Fragment>
        <YouTube channel='youtube' isOpen={this.state.isOpen} videoId={this.id} onClose={() => this.setState({isOpen: false})} />
        <Video>
          <div>
            <h4>Play Video</h4>
            <button onClick={this.openModal}>
              <PlayButton />
            </button>
          </div>
        </Video>
      </React.Fragment>
    )
  }
}

// const Youtube = styled(YoutubeModal)`
//   width: 100%;
//   .wrapper {
//     width: 100% !important;
//     display: block !important;
//   }
// `
const Video = styled.div`
  ${centerize};
  position: relative;
  min-height: 360px;
  transform: skew(0deg, 3deg);
  overflow: hidden;
  background: url(${dot}) repeat center / 50%,
    url(${bk}) no-repeat center / cover;
  div {
    ${alignVertical};
    h4 {
      ${DIN}
      ${BigMessage};
      color: ${colors.cl_white};
      width: 190px;
      margin: 0 auto 30px;
      text-align: center;
      transform: skew(0deg, -3deg);
    }
  }
`

export default Component
