import React from 'react'
import styled from 'styled-components'
import { ButtonWithRightArrow } from '../../atoms/buttons/LinkButtons'
import breakpoints from '../../breakpoints'
import { BigMessage } from '../../tokens/fontStyles'
import Title from '../../atoms/HeadingWithUnderbar'
import colors from '../../tokens/colors'
import { flex, alignCenter, alignVertical } from '../../tokens/positionings'
import { restrictWidth } from '../../tokens/sizing'
import ImagePc from '../../../images/ceo-pc.png'
import ImageSp from '../../../images/ceo-sp.png'

const Component = props => {
  return (
    <React.Fragment>
      <Section>
        <Title title="CEO Message" subTitle="代表メッセージ" margin={60} />
        <Wrapper>
          <WidthRestrictor>
            <TextContents>
              <h4>{props.message}</h4>
              <p>
                <span>{props.position}</span>
                {props.name}
              </p>
              <ButtonWithRightArrow link="message">
                Read More
              </ButtonWithRightArrow>
            </TextContents>
            <Figure />
          </WidthRestrictor>
        </Wrapper>
      </Section>
      <Message>
        <div>
          <p>{props.text}</p>
        </div>
      </Message>
    </React.Fragment>
  )
}

const Section = styled.section`
  padding-top: 60px;
  text-align: center;
`
const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -55%;
    width: 100%;
    height: 200px;
    transform: skewY(-5deg);
    background: ${colors.cl_red};
    z-index: -1;
    ${breakpoints.greaterThan('pc')`
      bottom: -80%;
      transform: skewY(-5deg);
    `}
  }
`
const WidthRestrictor = styled.div`
  ${restrictWidth(1020)};
  ${breakpoints.lessThan('pc')`
    ${alignVertical}
  `}
`
const TextContents = styled.div`
  padding-left: 40px;
  margin-bottom: 50px;
  text-align: left;
  width: 60%;
  ${breakpoints.lessThan('pc')`
    width: 100%;
    padding: 0 20px;
  `}
  h4 {
    ${BigMessage};
    white-space: pre-wrap;
    text-align: left;
    margin-bottom: 20px;
    ${breakpoints.lessThan('pc')`
      font-size: 2.4rem;
    `}
  }
  p {
    margin-bottom: 40px;
    font-size: 1.9rem;
    span {
      display: block;
      font-size: 1.2rem;
      white-space: pre-wrap;
    }
  }
`
const Figure = styled.figure`
  position: absolute;
  right: 0;
  bottom: -40%;
  width: 40%;
  height: 140%;
  z-index: -1;
  background: url(${ImagePc}) no-repeat right / contain;
  ${breakpoints.lessThan('pc')`
    width: 50%;
    max-width: 340px;
    background: url(${ImageSp}) no-repeat right / contain;
  `}
`
const Message = styled.div`
  ${flex('center')};
  ${alignCenter};
  min-height: 320px;
  position: relative;
  margin-top: -10px;
  padding: 80px 0 40px;
  font-size: 1.8rem;
  text-align: center;
  z-index: 20;
  white-space: pre-wrap;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -24px;
    transform: skewY(3deg);
    width: 100%;
    height: 100%;
    background: ${colors.cl_tit_white};
    z-index: 10;
  }
  ${breakpoints.lessThan('pc')`
    font-size: 1.4rem;
  `}
  div {
    position: relative;
    padding: 0 40px;
    z-index: 30;
    p {
      margin-bottom: 20px;
      font-size: 1.8rem;
    }
  }
`

export default Component
