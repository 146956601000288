import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import MainVisual from '../components/modules/visuals/TheMainVisual'
import RecruitMessage from '../components/modules/containers/FoldableMessageContainer'
import CeoMessage from '../components/modules/containers/MessageContainer'
import Video from '../components/modules/media/DiagonalVideo'
import Feature from '../components/organisms/DiagonalContainerOrganizer'
import SEO from '../components/seo'


export const query = graphql`
  {
    microcmsTop {
      id
      mv {
        url
      }
      mvSP {
        url
      }
      recTitle
      recTextVisible
      recTextHidden
      youtube
    }
    microcmsCeoProfile {
      copy
      name
      image {
        url
      }
      position
      comment
    }
    allMicrocmsFeatures {
      edges {
        node {
          id
          title
          image {
            url
          }
          imageSP {
            url
          }
          url
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const top = data.microcmsTop
  const ceo = data.microcmsCeoProfile
  return (
    <Layout topflag={true}>
      <SEO title="TOP" />
      <MainVisual pc={top.mv.url} sp={top.mvSP.url} />
      <RecruitMessage
        title={top.recTitle}
        visible={top.recTextVisible}
        hidden={top.recTextHidden}
      />
      <CeoMessage
        message={ceo.copy}
        position={ceo.position}
        name={ceo.name}
        text={ceo.comment}
      />
      <Video id={top.youtube} />
      <Feature data={data.allMicrocmsFeatures.edges} />
    </Layout>
  )
}

export default IndexPage
