import React from 'react'
import styled from 'styled-components'
import Title from '../atoms/HeadingWithUnderbar'
import DiagonalVisual from '../modules/visuals/DiagonalVisual'

const Component = ({ data }) => {
  let features = []
  for (let feature of data) {
    features.push(<DiagonalVisual data={feature.node} />)
  }
  return (
    <Section>
      <Title title="Feature" subTitle="百楽荘の特徴" margin={60} />
      {features}
    </Section>
  )
}
const Section = styled.section`
  margin-top: 60px;
  text-align: center;
`
export default Component
