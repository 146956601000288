import React from 'react'
import styled from 'styled-components'
import colors from '../../tokens/colors'
import breakpoints from '../../breakpoints'
import Title from '../../atoms/HeadingWithUnderbar'
import { BigMessage } from '../../tokens/fontStyles'
import { OutlinedStyle } from '../../atoms/buttons/FunctionalButons'
// import image from '../../../images/people.jpg'
import image from '../../../images/bg_message_2.jpg'
import message_image from '../../../images/MAKE_YOUR_LIFE.png'

const Component = props => {
  return (
    <Section>
      <div>
        <span>
          <Title title="Recruit Message" subTitle="リクルートメッセージ" />
          <Message>{props.title}</Message>
          <Text>{props.visible}</Text>
          <details>
            <summary>Read More</summary>
            <Text>{props.hidden}</Text>
          </details>
        </span>
      </div>
    </Section>
  )
}

const Section = styled.section`
  min-height: 580px;
  background: url(${image}) no-repeat bottom right 12% / cover;
  text-align: center;
  color: ${colors.cl_white};
  > div {
    width: 100%;
    height: 100%;
    /* background-color: rgba( 142,127,88, .2); */
    background-color: rgba(0, 0, 0, 0.4);
    padding: 40px 20%;
    ${breakpoints.lessThan('pc')`
      padding: 60px 20px;
    `}
    >span {
      display: block;
      width: 100%;
      height: 100%;
      background: url(${message_image}) no-repeat center / 320px;
    }
  }
  details {
    summary {
      ${OutlinedStyle};
      margin-bottom: 24px;
      list-style: none;
      :focus {
        outline: none;
      }
    }
  }
`

const Message = styled.h4`
  ${BigMessage};
  margin: 60px auto;
  white-space: pre-wrap;
  ${breakpoints.lessThan('pc')`
    margin: 40px auto;
  `}
`

const Text = styled.p`
  margin-bottom: 40px;
  white-space: pre-wrap;
  ${breakpoints.lessThan('pc')`
    margin-bottom: 20px;
  `}
`

export default Component
