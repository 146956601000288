import React from 'react'
import styled, { css } from 'styled-components'
import { fixRatio } from '../../tokens/sizing'
import breakpoints from '../../breakpoints'

const Component = ({ pc, sp }) => {
  return <Image pc={pc} sp={sp} />
}

const Image = styled.div`
  background: url(${props => props.pc}) center / cover no-repeat;
  ${fixRatio('47/130')}
  min-height: 480px;
  ${breakpoints.lessThan('pc')`
    ${props =>
      props.sp
        ? css`
            //true
            background: url(${props.sp}) center / cover no-repeat;
          `
        : css`
            //false
            background: url(${props.sp}) center / cover no-repeat;
          `}
  `}
`

export default Component
