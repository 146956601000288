import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonWithRightArrow } from '../../atoms/buttons/LinkButtons'
import colors from '../../tokens/colors'
import breakpoints from '../../breakpoints'

const Component = ({ data }) => {
  const imageSP = data.imageSP ? data.imageSP.url : ''
  return (
    <DiagonalVisual image={data.image.url} imageSP={imageSP}>
      <section>
        <TextContents>
          <h3>{data.title}</h3>
          <ButtonWithRightArrow link={data.url}>Read Mode</ButtonWithRightArrow>
        </TextContents>
      </section>
    </DiagonalVisual>
  )
}

const DiagonalVisual = styled.li`
  position: relative;
  width: 100%;
  section {
    position: relative;
    transform: skew(0deg, -5deg);
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(13, 13, 13, 0.47) 0%,
        rgba(247, 247, 247, 0) 100%
      );
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    &::after {
      content: '';
      background: url(${props => props.image}) no-repeat center / cover;
      width: 100%;
      height: calc(100% + 100vw * 0.087488663525924);
      display: block;
      position: absolute;
      top: calc(50vw * 0.087488663525924 * -1);
      left: 0;
      transform: skew(0deg, 5deg);
      ${breakpoints.lessThan('pc')`
      ${props =>
        props.imageSP
          ? css`
              background: url(${props => props.imageSP}) no-repeat center /
                cover;
            `
          : css`
              //false
            `}
    `}
    }
  }
`
const TextContents = styled.div`
  position: relative;
  z-index: 3;
  transform: skew(0deg, 5deg);
  padding: 30vh 0;
  padding-left: 92px;
  color: $cl_tit_white;
  text-align: left;
  ${breakpoints.lessThan('pc')`
  padding-left: 20px;
`}
  h3 {
    margin-bottom: 40px;
    color: ${colors.cl_white};
    font-size: 3.6rem;
    line-height: 180%;
    letter-spacing: 0.1em;
    white-space: pre-wrap;
  }
`

export default Component
